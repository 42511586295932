/**
 * 配置
 * 表格列
 */
export const columns = [
	{
		title: "订单号",
		dataIndex: "code",
		width: "25%",
	},
	{
		title: "jcook订单号",
		dataIndex: "jcookCode",
		width: "25%",
	},
	{
		title: "创建时间",
		dataIndex: "createDate",
		width: "15%",
	},
	{
		title: "付款方式",
		dataIndex: "payType",
		width: "15%",
		customRender: function (payType) {
			switch (payType) {
			case 1:
				return '支付宝'
			case 2:
				return '微信'
			case 3:
				return '现金'
			case 4:
				return 'pos'
			case 5:
				return '预缴扣除'
			case 6:
				return '银行卡'
			case 7:
				return '支票'
			case 8:
				return '转账'
			case 9:
				return '开发商代付'
			default:
				break;
			}
		}
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		scopedSlots: { customRender: "action" },
	},
]
export const detailColumns =  [
	{
		title: "商品",
		dataIndex: "skuName",
		width: "16%",
		scopedSlots: { customRender: "skuName"}
	},
	{
		title: "商品类别",
		dataIndex: "kind",
		width: "15%",
		customRender:function(kind){
			switch (kind) {
			case 0:
				return '未知'
			case 1:
				return '自营'
			case 2:
				return '其他'
			default:
				break;
			}
		}
	},
	{
		title: "商品单价",
		dataIndex: "sellPrice",
		width: "10%",
		customRender:function(sellPrice){
			return '￥'+sellPrice
		}
	},
	{
		title: "数量",
		dataIndex: "num",
		width: "8%",
	},
	{
		title: "单位",
		dataIndex: "unit",
		width: "12%",
	},
	{
		title: "总金额",
		width: "12%",
		customRender:function(data){
			return '￥'+data.sellPrice*data.num
		}
		
	},
	{
		title: "实收款",
		dataIndex: "payPrice",
		width: "15%",
		customRender:function(payPrice){
			return '￥'+payPrice
		}
	},
]
// 搜索项
export const searchForm = {
	code: "",
	jcookCode: '',
	tradeStatus:undefined
}
// 分页
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}