import httpService from "@/request"

// 订单
// list
export function orderList(params) {
	return httpService({
		url: `/user/shopOrder/list`,
		method: 'get',
		params: params,
	})
}
// 订单详情
export function orderInfo(params) {
	return httpService({
		url: `/user/shopOrder/findDetail`,
		method: 'get',
		params: params,
	})
}
// 取消订单
export function shopOrderCancel(params) {
	return httpService({
		url: `/user/shopOrder/cancel`,
		method: 'get',
		params: params,
	})
}

// 根据ID查询订单记录
export function getOrderRecord(params) {
	return httpService({
		url: `/user/shopOrder/orderRecord`,
		method: 'get',
		params: params,
	})
}
