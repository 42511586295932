<template>
  <div>
    <div class="cardTitle">订单列表</div>
    <div class="search-box">
      <a-row>
        <a-col :span="20">
          <a-space size="large">
            <a-input
              style="width: 200px"
              v-model="searchForm.code"
              placeholder="请输入订单号"
            />
            <a-input
              style="width: 200px"
              v-model="searchForm.jcookCode"
              placeholder="请输入jcook订单号"
            />
            <a-select
              style="width: 200px"
              v-model="searchForm.tradeStatus"
              placeholder="请选择交易状态"
            ></a-select>
            <a-button type="primary" @click="getData">查 询</a-button>
            <a-button>重 置</a-button>
          </a-space>
        </a-col>
        <a-col :span="4">
          <!-- <a-button class="add-btn" @click="">添加</a-button> -->
        </a-col>
      </a-row>
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :rowKey="(record) => record.id"
      tableLayout="auto"
      :pagination="pagination"
	  @change="handlerPage"
    >
      <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
        <a-descriptions title="订单信息">
          <!-- 商品图片 -->
          <!-- <template>
						<img :src="ImgUrl(record)" class='order-pic' alt="">
					</template> -->
          <a-descriptions-item label="订单状态">
            {{ record.tradeStatus | tradeStatus }}
          </a-descriptions-item>
          <a-descriptions-item label="支付人">
            {{ record.payName + record.payTel }}
          </a-descriptions-item>
          <a-descriptions-item label="付款金额">
            {{ record.payPrice }}
          </a-descriptions-item>
          <a-descriptions-item label="运费">
            {{ record.freightFee }}
          </a-descriptions-item>
          <a-descriptions-item label="收货人">
            {{ record.receiverName + record.receiverTel }}
          </a-descriptions-item>
          <a-descriptions-item label="地址">
            {{ record.locationName + record.addressDetail }}
          </a-descriptions-item>
          <a-descriptions-item label="备注">
            {{ record.remake || "无" }}
          </a-descriptions-item>
          <a-descriptions-item label="创建人">
            {{ record.createName }}
          </a-descriptions-item>
          <a-descriptions-item label="创建时间">
            {{ record.createDate }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <span slot="action" slot-scope="text, record">
        <a-space>
          <a @click="detail(record)">查看详情</a>
		  <!-- 2和4才可以取消 -->
          <a v-if="record.tradeStatus===2||record.tradeStatus===4" @click="cancelOrder(record.id)">取消订单</a>
        </a-space>
      </span>
    </a-table>
    <a-modal
      title="取消订单"
      :visible="modal.show"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <a-select placeholder='请选择原因' v-model="cancelForm.cancelReasonCode" style="width:200px">
          <a-select-option v-for="item in options.cancelReasonCode" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
        </a-select>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { orderList, shopOrderCancel, orderInfo } from "@/api/shop/order";
import { columns, pagination, searchForm } from "./depend/config";
export default {
	data() {
		return {
			tableData: [],
			modal: {
				show: false,
			},
			columns,
			pagination,
			searchForm,
			options: {
				cancelReasonCode: [
					{
						id: 1,
						name: "订单不能按预计时间送达",
					},
					{
						id: 2,
						name: "其他渠道价格更低",
					},
					{
						id: 3,
						name: "该商品降价了",
					},
					{
						id: 4,
						name: "不想买了",
					},
					{
						id: 5,
						name: "其他原因",
					},
					{
						id: 6,
						name: "操作有误(商品、地址等选错)",
					},
					{
						id: 7,
						name: "商品无货",
					},
					{
						id: 100,
						name: "其他",
					},
				],
			},
			cancelForm: {
				orderId: undefined,
				cancelReasonCode: undefined,
			},
		};
	},
	filters: {
		tradeStatus: function (value) {
			switch (value) {
			// 交易状态(0.交易创建并等待买家付款,1.未付款交易超时关闭或支付完成后全额退款,
			// 2.交易支付成功（待发货）,3.交易结束并不可退款，4.出库（待收货）,5.订单完成,
			// 6.申请取消,7.申请拒收，8.取消订单失败9.取消订单成功10.发生拆单,11.售后换新)
			case 0:
				return "交易创建并等待买家付款";
			case 1:
				return "交易关闭";
			case 2:
				return "待发货";
			case 3:
				return "交易结束";
			case 4:
				return "待收货";
			case 5:
				return "订单完成";
			case 6:
				return "申请取消";
			case 7:
				return "申请拒收";
			case 8:
				return "取消订单失败";
			case 9:
				return "取消订单成功";
			case 10:
				return "发生拆单";
			case 11:
				return "售后换新";
			default:
				break;
			}
		},
	},
	mounted() {
		this.getData();
	},
	methods: {
		async getData() {
			const res = await orderList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				...this.searchForm,
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total;
		},
		reset() {
			this.searchForm = {
				code: "",
				jcookCode: "",
				tradeStatus: undefined,
			};
			this.getData();
		},
		cancelOrder(id) {
			this.cancelForm.orderId = id;
			this.modal.show = true;
		},
		async handleOk() {
			let res = await shopOrderCancel(this.cancelForm);
			if (res.code === 200) {
				this.$message.success(res.msg);
				this.getData();
				this.handleCancel();
			} else {
				this.$message.error(res.msg);
			}
		},
		handleCancel() {
			this.cancelForm = {
				orderId: undefined,
				cancelReasonCode: undefined,
			}
			this.modal.show = false;
		},
		detail(data) {
			this.$router.push({ name: "OrderDetail", params: { id: data.id } });
		},
		//分页
		handlerPage(value) {
			this.pagination.current = value.current;
			this.pagination.pageSize = value.pageSize;
			this.getData()
		} 
	},
};
</script>
<style lang="less" scoped></style>
